import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import "./register.css";

export const Register = () => {
  const secret_key = "6LfkXSElAAAAAA0oAkMb9ZHeQte2vD6iHDJlhq_C";
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  const captcha = useRef(null);

  const onChange = (value) => {
    setRecaptchaValue(value);
    if (captcha.current.getValue()) {
      console.log("El usuario no es un robot");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!recaptchaValue) {
      alert("Debes verificar que no eres un robot");
      return;
    }

    if (inputValue === "") {
      alert("Ingresa un dato");
      return;
    }
    window.location.href = "/certificados/" + inputValue;
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="container py-5">
      <div className="row">
        {/* Sección izquierda con la descripción */}
        <div className="col-md-6">
          <div className="p-4 rounded">
            <div className="icon-container mb-3">
              <FontAwesomeIcon
                icon={faFile}
                size="2x"
                style={{ color: "#876A02", width: "25px", height: "33px" }}
              />
            </div>
            <br />
            <h2 className="mb-3 font-metropolis tittle">
              <i className="bi bi-file-earmark-text"></i> Certificados
            </h2>
            <p className="font-roboto text">
              Verifique fácilmente la validez de su certificado de cursos,
              diplomados y actividades académicas que no requieren título
              profesional.
            </p>
            <div className="alert alert-warning mt-4" role="alert">
              <p className="font-metropolis info-title">
                <strong>Información</strong>
              </p>
              <p className="font-roboto info-text">
                Si tienes algún inconveniente o duda frente a este tema, por
                favor escribir a{" "}
                <a href="mailto:contacto@iudigital.edu.co">
                  contacto@iudigital.edu.co
                </a>
                .
              </p>
            </div>
          </div>
        </div>

        {/* Sección derecha con el formulario */}
        <div className="col-md-6">
          <div className=" p-4 rounded input-document">
            <h3 className="mb-3 text-center font-metropolis">
              Ingresa tu número de documento
            </h3>
            <p className="text-center font-roboto">
              Ingrese su identificación y haga clic en "Consultar". El sistema
              lo dirigirá automáticamente a su certificado digital.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <input
                  className="form-control"
                  id="identificacion"
                  type="text"
                  placeholder="Documento de identidad"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3 ReCAPTCHA">
                <ReCAPTCHA
                  ref={captcha}
                  sitekey={secret_key}
                  onChange={onChange}
                />
              </div>
              <div className="button-container font-metropolis d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-danger w-30 text-center"
                >
                  CONSULTAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
